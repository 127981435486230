import './Partners.css'

export default function Partners() {
    return (
        <div className='partners' id='partners'>
            <div className="partners_content">
                <h1 className="partners_title">OUR PARTNERS</h1>
                <div className='partners_container'>
                    <img src='images/tsqatl.png'></img>
                </div>
            </div>
        </div>
    )
}